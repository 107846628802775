<template>
  <Modal
    id="modal_listes_confirm_merge"
    element-to-focus="input"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('liste.fusionner-listes') }}
    </template>
    <template #content>
      <p class="text-regular">
        {{ $t('liste.produits-listes-selectionnees-rassembles-nouvelle-liste') }}
      </p>
      <InputClassic
        v-model="nameInput"
        :label="$t('liste.nom-de-la-liste')"
        :placeholder="$t('liste.exemple-terminale')"
        @keyup.enter.native="mergeLists()"
      />
    </template>
    <template #footer>
      <div class="flex-vcenter">
        <ButtonClassic
          variant="solid"
          :label="$t('action.valider')"
          color="primary"
          icon="right"
          :disabled="!nameInput"
          @click="mergeLists()"
        >
          <template #right-icon>
            <UilCheckCircle />
          </template>
        </ButtonClassic>
      </div>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic,
  InputClassic,
  Modal,
} from "@lde/core_lde_vue";

import { UilCheckCircle } from "@iconscout/vue-unicons";

/**
 * Modale permettant de fusionner des listes en une seule.
 */
export default {
  name: "ModalListesConfirmMerge",
  components: {
    ButtonClassic,
    Modal,
    InputClassic,
    UilCheckCircle,
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton de confirmation de fusion.
     * @param {Object} - Contient le nom de la liste.
     */
    "confirm",
  ],
  data() {
    return {
      nameInput: "",
    };
  },
  methods: {
    /**
     * Envoie la confirmation de la fusion des listes.
     */
    mergeLists() {
      this.$emit("confirm", { listName: this.nameInput });
    },
    /**
     * Réinitialise toutes les valeurs une fois que la modale a été fermée.
     */
    hide() {
      this.nameInput = null;
      this.$modal.hide("modal_listes_confirm_merge");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_listes_confirm_merge {
  .modal-content { width: 73.6rem; }

  .modal-body {
    overflow: unset;
    > *:not(:last-child) { margin-bottom: var.$space-x-tiny; }
  }
}
</style>
