var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listes-table"},[_c('TableClassic',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"disabled":_vm.variant === 'transfer',"checkboxes":_vm.variant === 'active',"active-sort":_vm.activeSort},on:{"sort":function($event){return _vm.$emit('sort', $event)}},scopedSlots:_vm._u([{key:"libelle",fn:function({ value, }){return [_vm._v(" "+_vm._s(value.libelle || _vm.$t('liste.votre-liste'))+" ")]}},{key:"id",fn:function({ value, }){return [_vm._v(" "+_vm._s(value.id)+" ")]}},{key:"date",fn:function({ value, }){return [_c('span',{staticClass:"text-regular"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.variant === "active" ? value.date_creation : value.date_transfert,_vm.$t("global.format-date")))+" ")])]}},{key:"quantite",fn:function({ value, }){return [_vm._v(" "+_vm._s(_vm.$tc("liste.x-article", value.quantite_ligne, { article: value.quantite_ligne, }))+" ")]}},{key:"statut",fn:function({ value, }){return [_c('TimelineLabel',{attrs:{"label":_vm.defineStatus(value).label,"tooltip":{
          title: _vm.$t('devis-commande.tooltip.liste-creee-doit-etre-transformee-devis'),
          placement: 'top',
        },"status":_vm.defineStatus(value).status,"counter-label":_vm.defineStatus(value).deliveryLabel,"small":""}})]}},{key:"sous-total",fn:function({ value, }){return [_c('div',[(value.total_ttc !== value.total_ht)?_c('strong',{staticClass:"all-taxes",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ttc', { prix: _vm.moneyFilter(value.total_ttc), }) )}}):_vm._e(),_c('span',{staticClass:"duty-free",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ht', { prix: _vm.moneyFilter( value.total_ht), }))}})])]}},{key:"actions",fn:function({ value, }){return [_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('liste.envoyer'),
          delay: { show: 800, },
        }),expression:"{\n          content: $t('liste.envoyer'),\n          delay: { show: 800, },\n        }"}],attrs:{"variant":"solid","color":"primary","icon":"left","disabled":_vm.isImpersonating},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-transfer', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('IconBookToDevisRight')]},proxy:true}],null,true)}),_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('action.mettre-poubelle'),
          delay: { show: 800, },
        }),expression:"{\n          content: $t('action.mettre-poubelle'),\n          delay: { show: 800, },\n        }"}],attrs:{"variant":"special","color":"primary","icon":"left","size":"small","disabled":_vm.isImpersonating},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-delete', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('UilTrashAlt')]},proxy:true}],null,true)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }