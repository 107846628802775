<template>
  <div class="listes-table">
    <TableClassic
      :columns="columns"
      :rows="rows"
      :disabled="variant === 'transfer'"
      :checkboxes="variant === 'active'"
      :active-sort="activeSort"
      @sort="$emit('sort', $event)"
    >
      <template #libelle="{ value, }">
        {{ value.libelle || $t('liste.votre-liste') }}
      </template>
      <template #id="{ value, }">
        {{ value.id }}
      </template>
      <template #date="{ value, }">
        <span class="text-regular">
          {{ variant === "active" ? value.date_creation : value.date_transfert | moment($t("global.format-date")) }}
        </span>
      </template>
      <template #quantite="{ value, }">
        {{ $tc("liste.x-article", value.quantite_ligne, { article: value.quantite_ligne, }) }}
      </template>
      <template #statut="{ value, }">
        <TimelineLabel
          :label="defineStatus(value).label"
          :tooltip="{
            title: $t('devis-commande.tooltip.liste-creee-doit-etre-transformee-devis'),
            placement: 'top',
          }"
          :status="defineStatus(value).status"
          :counter-label="defineStatus(value).deliveryLabel"
          small
        />
      </template>
      <template #sous-total="{ value, }">
        <div>
          <strong
            v-if="value.total_ttc !== value.total_ht"
            class="all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(value.total_ttc), }) "
          />
          <span
            class="duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter( value.total_ht), })"
          />
        </div>
      </template>
      <template #actions="{ value, }">
        <ButtonClassic
          v-tooltip="{
            content: $t('liste.envoyer'),
            delay: { show: 800, },
          }"
          variant="solid"
          color="primary"
          icon="left"
          :disabled="isImpersonating"
          @click.native.stop="$emit('click-transfer', value)"
        >
          <template #left-icon>
            <IconBookToDevisRight />
          </template>
        </ButtonClassic>
        <ButtonClassic
          v-tooltip="{
            content: $t('action.mettre-poubelle'),
            delay: { show: 800, },
          }"
          variant="special"
          color="primary"
          icon="left"
          size="small"
          :disabled="isImpersonating"
          @click.native.stop="$emit('click-delete', value)"
        >
          <template #left-icon>
            <UilTrashAlt />
          </template>
        </ButtonClassic>
      </template>
    </TableClassic>
  </div>
</template>

<script>
import { ButtonClassic, moneyFilter } from "@lde/core_lde_vue";

import TableClassic from "@/components/table/TableClassic.vue";
import TimelineLabel from "@/components/timeline/TimelineLabel.vue";

import IconBookToDevisRight from "@/components/icons/IconBookToDevisRight.vue";

import { UilTrashAlt } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche les listes.
 */
export default {
  name: "ListesTable",
  components: {
    ButtonClassic,
    TableClassic,
    TimelineLabel,
    IconBookToDevisRight,
    UilTrashAlt,
  },
  props: {
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Change le contenu et l'affichage du composant selon le contexte.
     */
    variant: {
      type: String,
      validator: (value) => ["active", "transfer"].includes(value),
      default: "active",
    },
    /**
     * Clé et sens du tri.
     */
    activeSort: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton de suppression.
     * @param {Number} - ID de la ligne.
     */
    "click-delete",
    /**
     * Déclenché au clic sur le bouton de transfert.
     * @param {Number} - ID de la ligne.
     */
    "click-transfer",
  ],
  computed: {
    ...mapGetters(["hasPerm"]),
    columns() {
      const cols = [
        {
          key: "libelle",
          name: this.$t("liste.intitule"),
        },
        {
          key: "id",
          name: this.$t("liste.numero"),
        },
        {
          key: "date",
          name: this.$t("date.date"),
          sortKey: this.variant === "active" ? "date_creation" : "date_transfert",
        },
        {
          key: "quantite",
          name: this.$t("general.quantite"),
          sortKey: "quantite_ligne",
        },
        {
          key: "statut",
          name: this.$t("general.statut"),
          notSortable: true,
        },
        {
          key: "sous-total",
          name: this.$t("general.sous-total"),
          sortKey: "total_ht",
        },
      ];

      if (this.variant === "active") {
        cols.push({
          key: "actions",
          notSortable: true,
        });
      }

      return cols;
    },
  },
  methods: {
    moneyFilter,
    /**
     * Définit le statut de la liste.
     * @param {Object} ligne Infos de la ligne.
     */
    defineStatus(ligne) {
      let status = "valid";
      let label = this.$t("timeline.liste");

      switch (ligne.statut_affiche) {
        case "transferee":
          label = this.$t("timeline.transferee");
          status = "transferee";
          break;
        default:
          break;
      }

      return {
        label,
        status,
      };
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/listes_table.scss";
</style>
