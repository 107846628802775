<template>
  <Modal
    id="modal_listes_confirm_transfer"
    variant="warning"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('liste.envoyer-ma-liste-a-un-collegue') }}
    </template>
    <template #subtitle>
      {{ $t("liste.envoyer-equivaut-attribuer-propriete-liste") }}
    </template>
    <template #content>
      <div
        id="concerned_lists"
        class="text-medium"
      >
        <span v-html="$tc('liste.listes-concernees', listes.length)" />
        <strong v-if="listes.length === 1">{{ listes[0].libelle }}</strong>
        <!-- Laisser cette div sinon les puces du ul disparaissent en overflow -->
        <div v-else>
          <ul class="bold">
            <li
              v-for="liste in listes"
              :key="liste.id"
            >
              {{ liste.libelle }}
            </li>
          </ul>
        </div>
      </div>
      <SelectClassic
        v-model="user"
        :options="establishmentUsers"
        :internal-search="false"
        :label="$t('general.indiquez-nom-ou-courriel-destinataire')"
        :placeholder="
          $tc(
            'general.minimum-x-caracteres-pour-obtenir-des-suggestions',
            minCharacters,
            { x: minCharacters, }
          )"
        status="required"
        select-on-enter
        @search="fetchUsers"
      >
        <template #label-icon>
          <UilUser size="16" />
        </template>
      </SelectClassic>
    </template>

    <template #footer>
      <ButtonClassic
        variant="solid"
        :label="$t('action.valider-et-envoyer')"
        color="secondary"
        icon="right"
        size="medium"
        :disabled="!user"
        @click="transferLists()"
      >
        <template #right-icon>
          <IconBookToDevisRight />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { Modal, ButtonClassic, SelectClassic } from "@lde/core_lde_vue";

import IconBookToDevisRight from "@/components/icons/IconBookToDevisRight.vue";

import { UilUser } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

/**
 * Modale affichée pour confirmer le transfert de liste(s).
 */
export default {
  name: "ModalListesConfirmTransfer",
  components: {
    Modal,
    ButtonClassic,
    SelectClassic,
    IconBookToDevisRight,
    UilUser,
  },
  props: {
    /**
     * Listes sélectionnées pour transfert.
     */
    listes: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    /**
     * Déclenché lorsqu'on confirme le transfert.
     * @param {Object} user Utilisateur vers lequel transferer la liste.
     */
    "confirm",
  ],
  data() {
    return {
      minCharacters: 3,
      user: null,
      establishmentUsers: [],
    };
  },
  methods: {
    /**
     * Récupère tous les consultants, prescripteurs et valideurs de l'organisme actuel.
     * @param {String} searchText Texte tapé dans l'input.
     */
    fetchUsers(searchText = "") {
      if (searchText?.length >= this.minCharacters) {
        Api().get(`/utilisateur/transferable/?search=${searchText}`)
          .then((res) => {
            this.establishmentUsers = res.data.results.map(
              (user) => ({
                id: user.id,
                label: `${user.contact.prenom} ${user.contact.nom.toUpperCase()} - ${user.email}`,
                value: (`${user.contact.prenom}-${user.contact.nom}-${user.email}`).toLowerCase(),
              }),
            );
          });
      }
    },
    transferLists() {
      this.$emit("confirm", this.user);
      this.hide();
    },
    /**
     * Ferme la modale à la confirmation.
     */
    hide() {
      this.$modal.hide("modal_listes_confirm_transfer");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_listes_confirm_transfer {
  .modal-content { max-width: 70rem; }

  .modal-body {
    overflow: unset;
    background-color: unset;
  }

  #concerned_lists {
    color: var.$gray-medium;
    strong { margin-left: var.$space-xx-tiny; }
    > div {
      overflow-y: auto;
      max-height: 15rem;
    }
    ul { margin-left: var.$space-tiny; }
  }

  .select-classic {
    display: block;
    margin-top: var.$space-tiny;
  }
}</style>
